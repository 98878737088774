import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <header className="header">
          <div className="inner">
            <div className="row header-top">
              <div className="four offset-by-six columns">
                <nav>
                  <ol className="uilist-hor nav-top">
                    <li className="first">
                      <a href="/fr/contact/">Contact</a>
                    </li>
                    <li>
                      <a href="/fr/lexique/">Lexique</a>
                    </li>
                    <li className="last">
                      <a href="/fr/faq-questions-diarrhee/">FAQ</a>
                    </li>
                  </ol>
                  <ul className="uilist-hor nav-top nav-lang">
                    <li id="uilist-hor nav-top nav-lang_nl-BE">
                      <a href="/diarree-op-reis/reizigersdiaree-vermijden/">
                        NL
                      </a>
                    </li>
                    <li id="uilist-hor nav-top nav-lang_fr" className="active">
                      <a href="/fr/diarrhee-en-voyage/eviter-la-diarrhee-du-voyageur/">
                        FR
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
              <div className="two columns">
                <form
                  className="search"
                  method="post"
                  action="/"
                >
                  <div className="hiddenFields">
                    <input
                      type="hidden"
                      name="XID"
                      defaultValue="364a8b82d27dc4ed8153f9ee3e380024a9c9a2be"
                    />
                    <input type="hidden" name="ACT" defaultValue="45" />
                    <input
                      type="hidden"
                      name="result_page"
                      defaultValue="/fr/chercher"
                    />
                    <input
                      type="hidden"
                      name="collection"
                      defaultValue="pages"
                    />
                    <input
                      type="hidden"
                      name="search_mode"
                      defaultValue="all"
                    />
                    <input type="hidden" name="site_id" defaultValue="1" />
                  </div>
                  <div className="row">
                    <div className="twelve columns">
                      
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="row header-bottom">
              <div className="two columns">
                <span className="logo">
                  <a href="/fr/">Imodium®</a>
                </span>
              </div>
              <div className="ten columns">
                <nav className="top-bar">
                  <ul className="nav-info">
                    <li className="name">
                      <h1>
                        <a href="#">Menu</a>
                      </h1>
                    </li>
                    <li className="toggle-topbar">
                      <a href="#" />
                    </li>
                  </ul>
                  <section>
                    <ol className="uilist-hor nav-main">
                      <li className="first" id="nav-sub-4">
                        <a href="/fr/comment-agit-imodium/">
                          Comment agit
                          <br />
                          IMODIUM®?
                        </a>
                      </li>
                      <li id="nav-sub-5">
                        <a href="/fr/medicaments-imodium/">
                          Médicaments <br />
                          IMODIUM®
                        </a>
                      </li>
                      <li id="nav-sub-6">
                        <a href="/fr/la-diarrhee/">
                          Diarrhée – causes
                          <br />
                          et traitement
                        </a>
                      </li>
                      <li className="parent-active" id="nav-sub-8">
                        <a href="/fr/diarrhee-en-voyage/">
                          Diarrhée
                          <br />
                          en voyage
                        </a>
                      </li>
                      <li id="nav-sub-9">
                        <a href="/fr/diarrhee-chez-les-enfants/">
                          Diarrhée chez
                          <br />
                          les enfants
                        </a>
                      </li>
                      <li className="last" id="nav-sub-10">
                        <a href="/fr/materiel-dinformation-diarrhee/">
                          Matériel
                          <br />
                          d'information
                        </a>
                      </li>
                    </ol>
                  </section>
                </nav>
                <script
                  type="text/javascript"
                  dangerouslySetInnerHTML={{
                    __html:
                      '\nvar item = document.getElementById("nav-sub-10").getElementsByTagName("a");\nvar span = document.createElement("span");\nspan.className = "menu-info";\nspan.innerHTML = " ";\nitem[0].appendChild(span);\n'
                  }}
                />
              </div>
            </div>
          </div>
        </header>
        <div className="main row">
          <div className="sidebar three columns">
            <ul className="side-nav">
              <li className="first overview">
                <a href="/fr/diarrhee-en-voyage/"> Diarrhée en voyage </a>
              </li>
              <li className="active">
                <a href="/fr/diarrhee-en-voyage/eviter-la-diarrhee-du-voyageur/">
                  Eviter la diarrhée du voyageur
                </a>
              </li>
              <li>
                <a href="/fr/diarrhee-en-voyage/traitement-de-la-diarrhee-du-voyageur/">
                  Traitement de la diarrhée du voyageur
                </a>
              </li>
              <li className="last">
                <a href="/fr/diarrhee-en-voyage/pharmacie-de-voyage/">
                  Pharmacie de voyage
                </a>
              </li>
            </ul>
            <div className="widget">
              <div className="inner">
                <h4>Pharmacie de voyage</h4>
                <div className="img">
                  <img
                    src="/assets/files/widgets/images/teaser_reisen.jpeg"
                    width="170"
                    height="102"
                    alt="Pharmacie de voyage"
                  />
                </div>
                <p>
                  Ce que doit contenir la&nbsp;
                  <strong>pharmacie de voyage</strong>? Trouvez ici toutes les
                  informations pratiques.
                </p>
                <a href="/fr/diarrhee-en-voyage/pharmacie-de-voyage/">
                  Plus d'info
                </a>
              </div>
            </div>
          </div>
          <div className="page nine columns page-129">
            <h1>Eviter la diarrhée du voyageur</h1>
            <div className="img">
              <img
                src="/assets/files/pages/reisen.jpeg"
                width="701"
                height="289"
                alt="Eviter la diarrhée du voyageur"
              />
            </div>
            <div className="intro row">
              <div className="three columns">
                <h2>Quelques conseils pour éviter la diarrhée du voyageur.</h2>
              </div>
              <div className="nine columns">
                <p>
                  Vous savez ce que c'est: vous vous préparez à profiter
                  pleinement de vos vacances et après quelques jours, vous les
                  passez à la toilette.
                  <br />
                  La diarrhée du voyageur est le plus souvent causée par
                  l'ingestion d'aliments ou d'eau contaminée. Parfois, des mets
                  très épicés ou une consommation exagérée d'alcool peuvent
                  aussi provoquer de la diarrhée. Dans de nombreux cas, les
                  symptômes peuvent être traités avec des antidiarrhéiques comme&nbsp;
                  <a href="/fr/medicaments-imodium/imodium-instant/">IMODIUM® Instant</a>. Les comprimés orodispersibles peuvent être pris sans
                  eau, une solution facile pour stopper la diarrhée en vacances
                </p>
              </div>
            </div>
            <h4>
              Retrouvez ci-dessous quelques conseils utiles pour éviter ces
              désagréments en voyage.
            </h4>
            <p>Astuces pour éviter de boire de l'eau contaminée</p>
            <ul>
              <li>
                Ne pas boire – ni se brosser les dents – avec de l'eau du
                robinet. Boire ou utiliser plutôt de l'eau en bouteille scellée
                ou de l'eau bouillie/purifiée. De même, évitez les glaçons faits
                avec de l'eau du robinet.
              </li>
              <li>
                Vous pouvez aussi boire d'autres boissons en bouteille ou en
                canette.&nbsp;Vérifiez que la bouteille n'était pas ouverte
                avant qu'on vous l'amène à table.
              </li>
              <li>
                Quand vous nagez, gardez votre tête hors de l'eau et essayez de
                ne pas l'avaler, l'eau pouvant être contaminée.
              </li>
              <li>
                N'ingérez pas d'eau lors de votre douche ou bain. Il vaut mieux
                ne pas chanter sous sa douhe!
              </li>
              <li>
                Vous n'avez pas de chance et souffrez de diarrhée du voyageur?
                Suivez les conseils qui suivent pour traiter la diarrhée du
                voyageur.
              </li>
            </ul>
            <p>&nbsp;</p>
            <p>Astuces pour éviter d'ingurgiter de la nourriture contaminée</p>
            <ul>
              <li>
                Assurez-vous que la nourriture soit correctement cuite et aussi
                chaude qu'elle devrait l'être.
              </li>
              <li>
                Veillez toujours à ce que la nourriture restante soit recouverte
                ou mise au réfrigérateur.
              </li>
              <li>Lavez-vous soigneusement les mains avant de manger.</li>
            </ul>
            <p>&nbsp;</p>
            <p>Essayez d'éviter tout ce qui suit :</p>
            <ul>
              <li>
                les aliments qui ont été lavés à l'eau du robinet, comme des
                fruits (épluchez-les afin de réduire les risques), des salades
                et des légumes crus ;
              </li>
              <li>les crèmes glacées d'origine douteuse ;</li>
              <li>les casse-croûtes provenant de vendeurs de rue;</li>
              <li>les viandes froides;</li>
              <li>les crustacés et fruits de mer crus;</li>
              <li>
                le lait non pasteurisé (dans le doute, le faire bouillir); et
              </li>
              <li>la nourriture réchauffée.</li>
            </ul>
          </div>
        </div>
        <Footer></Footer>
        <div id="myModal" className="reveal-modal large">
          <style
            dangerouslySetInnerHTML={{
              __html:
                ".embed-container { position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; } .embed-container video, .embed-container object, .embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }"
            }}
          />
          <div className="embed-container">
            <video
              src="/assets/video/imodium-2018-fr.mp4"
              poster="posterimage.jpg"
            />
          </div>
          <a className="close-reveal-modal">×</a>
        </div>
        <input type="hidden" name="language" defaultValue="fr" id="lg" />
        <script src="//ajax.googleapis.com/ajax/libs/jquery/3.4.1/jquery.min.js" />
        <script src="https://code.jquery.com/jquery-migrate-3.2.0.js"></script>
        <script
          dangerouslySetInnerHTML={{
            __html:
              "window.jQuery || document.write('<script src=\"/assets/js/vendor/jquery-3.4.1.min.js\"><\\/script>')"
          }}
        />
        <script src="/assets/js/vendor/jquery.flexslider-min.js" />
        <script src="/assets/js/vendor/hash.js" />
        <script src="/assets/js/foundation/jquery.foundation.navigation.js" />
        <script src="/assets/js/foundation/jquery.foundation.topbar.js" />
        <script src="/assets/js/foundation/jquery.foundation.forms.js" />
        <script src="/assets/js/foundation/jquery.foundation.reveal.js" />
        <script src="/assets/js/vendor/swfobject.js" />
        <script src="/assets/js/vendor/yepnope.1.5.4-min.js" />
        <script src="/assets/js/app.js" />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\nfunction cookiesDirectiveScriptWrapper(){\n// declare teh used cookie-3rd-parties\n}\n"
          }}
        />
        
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n// The position of the disclosure ('top' or 'bottom')\n// Number of times to display disclosure. Enter 0 to show it forever!!!!\n// The URI of your privacy policy\nif($(\"body\").hasClass(\"nl-BE\")){cookiesDirective('bottom',5,'/cookiebeleid');}else{cookiesDirective('bottom',5,'/fr/politique-en-matiere-de-cookies');}\n"
          }}
        />
      </div>
    );
  }
}

export default Page;
